<template>
    <div>
      
      
      <div v-if="role_type == 'vendor'">
        <VendorD></VendorD>
      </div>
       <div v-if="roles_id != '86476c72-4fb5-4b1c-926d-132c0c37abc8'">
        <AdminD></AdminD>
      </div>
      <!-- <div v-if="roles_id == '86476c72-4fb5-4b1c-926d-132c0c37abc8'"> -->
      <div v-if="roles_id == '86476c72-4fb5-4b1c-926d-132c0c37abc8'">
        <SalesD></SalesD>
      </div>
    </div>
    </template>
    <script>
    import  VendorD from "./VendorD1";
    import AdminD from "./AdminD";
    import SalesD from "./SalesD";
    export default {
      name: "Dashboard",
      data: () => ({
        title: "Dashboard",
        role_type: JSON.parse(localStorage.getItem("loggedUserData")).role_type,
        roles_id: JSON.parse(localStorage.getItem("loggedUserData")).role_id,
      }),
      components: {
      VendorD , AdminD,SalesD
      },
    };
    </script>
    <style scoped>
    
    </style>