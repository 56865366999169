<template>
	<div>
		<b-row>
			<b-col lg="6" md="6" sm="6" class="heading-title">
				<h4>Dashboard </h4>
			</b-col>
			<b-col lg="6" md="6" sm="6" class="btns-header">
				<div class="text-right mb-1 cursor-pointer" @click="filterClick()"><i class="mdi mdi-filter"></i> Filter</div>
			</b-col>
		</b-row>
		
        <br>
		<div class="card mb-4" v-if="filterEnable">
			<div class="card-header">
				<div class="d-flex justify-content-between align-items-center row py-3 gap-3 gap-md-0">
					<div class="col-md-3 product_status">
						<multiselect
						v-model="parent_selected"
						:options="parent_option"
						placeholder="Select Parent Venue"
						track-by="name"
						label="name"
						@input="callChildVenue()"
						>
						</multiselect>
					</div>
					<div class="col-md-3 product_category">
						<multiselect
						v-model="child_selected"
						:options="child_option"
						placeholder="Select Child Venue"
						track-by="name"
						label="name"
						>
						</multiselect>
					</div>
					<div class="col-md-3 product_stock">
						<select class="form-control"  v-model="select_mode"> 
							<option value="daily">Daily</option>
							<option value="Weekly">Weekly</option>
							<option value="Monthly">Monthly</option>
							<option value="Yearly">Yearly</option>
							<option value="custom">Custom</option>
						</select>
					</div>
					<div class="col-md-3 product_stock">
						<button class="btn btn-primary btn-block" @click="filterCheck"> Filter</button>
					</div>
				</div>
				<hr>
				<div class="d-flex justify-content-between align-items-center row py-3 gap-3 gap-md-0">
					<div class="col-md-8 product_status">
						<input class="form-control" placeholder="Search .." v-model="Booking_refno"> 
					</div>
					<div class="col-md-4 product_status">
						<button class="btn btn-primary btn-block" @click="target_selectChild()"> Search</button>
					</div>
				</div>
				
				
			</div>
			
		</div>
		
		<b-row>
			
			<div class="col-xl-4 mb-4 col-lg-4 col-12">
				<div class="card card-design" >
					<div class="d-flex align-items-end Drow">
						<div class="col-7">
							<div class="card-body text-nowrap">
								<div class="flex justify-content-between mt-3">
									<div>
										<h5 class="card-title mb-4">Hi! {{user_name}}</h5>
										
										<h4 class="text-light mb-5">Total Booking Value<br><span v-html="currency_check(numberWithCommas(booking_count.booking_value))"></span></h4>
									</div>
									<div class="text-right position-absolute " style="right: -138px;
    bottom: 10px;">
										<a href="https://venuebook.venuebook.in/venues" target="_balnk" class="btn btn-venuecolor waves-effect waves-light">View Profile</a>
									</div>
								</div>
							</div>
						</div>
						<div class="col-5 text-center text-sm-left">
							<div class="card-body pb-0 px-0 px-md-4">
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-xl-8 mb-4 col-lg-8 col-12">
				<div class="card h-100">
					<div class="card-header">
						<div class="d-flex justify-content-between mb-3">
							<h5 class="card-title mb-0">Statistics</h5>
							<small class="text-muted">Updated </small>
						</div>
					</div>
					<div class="card-body pt-0">
						<b-row>
							<b-col cols="12" lg="3" md="6">
								<div class="d-flex align-items-center cursor-pointer" @click="$router.push('/my-booking')">
									<div class="mr-2">
										<span class="display-4 text-success fs-5">
											<i class="mdi mdi-book"></i>
										</span>
									</div>
									<div>
										<span id="money_l" class="text-dark">Booked</span>
										<h5 class="font-medium mb-0"> {{ booking_count.total_confimed_booking }} <span class="text-success fs-10">{{sumofPercentage(booking_count.total_confimed_booking,this.allcounts)}}%</span></h5>
									</div>
								</div>
								<div class="progress w-75" style="height:4px">
                <div class="progress-bar bg-success" role="progressbar" :style="{width: sumofPercentage(booking_count.total_confimed_booking,this.allcounts)+'%'}" :aria-valuenow="sumofPercentage(booking_count.total_confimed_booking,this.allcounts)" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
							</b-col>
							
							<b-col cols="12" lg="3" md="6">
								<div class="d-flex align-items-center cursor-pointer" @click="$router.push('/my-booking')">
									<div class="mr-2">
										<span class="display-4 text-danger">
											<i class="mdi mdi-block-helper"></i>
										</span>
									</div>
									<div>
										<span id="money_l" class="text-dark">Blocked</span>
										<h5 class="font-medium mb-0">{{  booking_count.total_blocked_booking }} <span class="text-success fs-10">{{sumofPercentage(booking_count.total_blocked_booking,this.allcounts)}}%</span></h5>
									</div>
								</div>
								<div class="progress w-75" style="height:4px">
                <div class="progress-bar bg-danger" role="progressbar" :style="{width: sumofPercentage(booking_count.total_blocked_booking,this.allcounts)+'%'}" :aria-valuenow="sumofPercentage(booking_count.total_blocked_booking,this.allcounts)" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
							</b-col>
							<b-col cols="12" lg="3" md="6">
								<div class="d-flex align-items-center cursor-pointer" @click="$router.push('/drafts-booking')">
									<div class="mr-2">
										<span class="display-4 text-primary">
											<i class="mdi mdi-content-save"></i>
										</span>
									</div>
									<div>
										<span id="money_l" class="text-dark">Drafted</span>
										<h5 class="font-medium mb-0">{{ booking_count.total_draft_booking }} <span class="text-success fs-10">{{sumofPercentage(booking_count.total_draft_booking,this.allcounts)}}%</span></h5>
									</div>
								</div>
								<div class="progress w-75" style="height:4px">
                <div class="progress-bar bg-info" role="progressbar" :style="{width: sumofPercentage(booking_count.total_draft_booking,this.allcounts)+'%'}" :aria-valuenow="sumofPercentage(booking_count.total_draft_booking,this.allcounts)" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
							</b-col>
							<b-col cols="12" lg="3" md="6">
								<div class="d-flex align-items-center cursor-pointer" @click="$router.push('/drafts-booking')">
									<div class="mr-2">
										<span class="display-4 text-warning">
											<i class="mdi mdi-file-outline"></i>
										</span>
									</div>
									<div>
										<span id="money_l" class="text-dark">Quotation</span> 
										<h5 class="font-medium mb-0">{{ booking_count.total_quot_booking }} <span class="text-success fs-10">{{sumofPercentage(booking_count.total_quot_booking,this.allcounts)}}%</span></h5>
										
									</div>
								</div>
								<div class="progress w-75" style="height:4px">
                <div class="progress-bar bg-warning" role="progressbar" :style="{width: sumofPercentage(booking_count.total_quot_booking,this.allcounts)+'%'}" :aria-valuenow="sumofPercentage(booking_count.total_quot_booking,this.allcounts)" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
							</b-col>
						</b-row>
						<!--<div class="Drow gy-3">
							<div class="col-md-3 col-6 ">
							<h5 class="" >Booked</h5>
							<div class="d-flex align-items-center">
							<div class="card-info ml-2">
							<h5 class="mb-0">
							<span class="badge rounded-pill bg-label-info p-2" style="margin-left: -11px;">
							<i class="mdi mdi-cart"></i></span> 
							{{ booking_count.total_confimed_booking }} </h5>
							<small class="text-success">+{{sumofPercentage(booking_count.total_confimed_booking,'4')}}%</small>
							
							
							</div>
							</div>
							</div>
							<div class="col-md-3 col-6 text-center">
							<h5 class="text-center " >Blocked</h5>
							<div class="d-flex align-items-center">
							
							<div class="badge rounded-pill bg-label-info me-3 p-2"><i class="mdi mdi-cart d-fs-4"></i></div>
							
							<div class="card-info  ml-2">
							<h5 class="mb-0">{{  booking_count.total_blocked_booking }}</h5>
							<small class="text-success">+{{sumofPercentage(booking_count.total_blocked_booking,'4')}}%</small>
							</div>
							</div>
							</div>
							<div class="col-md-3 col-6 text-center">
							<h5 class="text-center" >Drafted</h5> 
							<div class="d-flex align-items-center">
							<div class="badge rounded-pill bg-label-danger me-3 p-2"><i class="mdi mdi-cart d-fs-4"></i></div>
							<div class="card-info  ml-2">
							<h5 class="mb-0">{{ booking_count.total_draft_booking }}</h5>
							<small class="text-success">+{{sumofPercentage(booking_count.total_draft_booking,'4')}}%</small>
							</div>
							</div>
							</div>
							<div class="col-md-3 col-6 text-center">
							<h5 class="text-center" >Quotation</h5> 
							<div class="d-flex align-items-center">
							<div class="badge rounded-pill bg-label-success p-2"><i class="mdi mdi-cart d-fs-4"></i></div>
							<div class="card-info  ml-2">
							
							<h5 class="mb-0">{{ booking_count.total_quot_booking }}</h5>
							<small class="text-success">+{{sumofPercentage(booking_count.total_quot_booking,'4')}}%</small>
							</div>
							</div>
							</div>
						</div>-->
					</div>
				</div>
			</div>
			<div class="col-xl-4 col-12 h-100">
				<div class="Drow">
					<!-- <div class="col-xl-6 mb-4 col-md-3 col-6">
						<div class="card">
						<div class="card-header pb-0">
						<h5 class="card-title mb-0">Income</h5>
						<h5 class="card-title mb-0">
						<span v-html="currency_check(numberWithCommas(booking_count.total_exp))"></span>
						</h5>
						<small class="text-muted">Orders</small>
						</div>
						<div class="card-body" style="position: relative;">
						
						<div id="profitLastMonth" style="min-height: 90px;">
						<vue-apex-charts
						type="bar"
						height="70px"
						width="120px"
						:options="referralChart.chartOptions"
						:series="referralChart.series"
						></vue-apex-charts>
						</div>
						<div class="resize-triggers"><div class="expand-trigger"><div style="width: 179px; height: 161px;"></div></div><div class="contract-trigger"></div></div></div>
						</div>
						</div>
						<div class="col-xl-6 mb-4 col-md-3 col-6">
						<div class="card">
						<div class="card-header pb-0">
						<h5 class="card-title mb-0">Expense</h5>
						<small class="text-muted">Last Month</small>
						</div>
						<div class="card-body" style="position: relative;">
						
						<div class="d-flex justify-content-between align-items-center mt-3 gap-3">
						<h4 class="mb-0">624k</h4>
						<small class="text-success">+8.24%</small>
						</div>
						<vue-apex-charts
						type="bar"
						height="70px"
						width="120px"
						:options="referralChart.chartOptions"
						:series="referralChart.series"
						></vue-apex-charts>
						<div class="resize-triggers"><div class="expand-trigger"><div style="width: 179px; height: 161px;"></div></div><div class="contract-trigger"></div></div></div>
						</div>
					</div> -->
					<div class="col-xl-12 mb-4 col-md-6 ">
						<div class="card h-100" >
							
							
							<div class="card-body" style="height: 411px;">
								
								<b-list-group class="mt-3">
									<b-list-group-item href="#" active class="list-group-header">
										
										<h5 class="m-0 card-title">Blocking Timer</h5>
										
										
									</b-list-group-item>
									
									<b-list-group-item href="#"  class="flex-column align-items-start"  v-for="(bt,b) in Block_details " :key="b">
										<!-- <div class="d-flex w-100 justify-content-between">
											<h5 class="mb-1">{{bt.billing_first_name}}</h5>
											<div> {{bt.start_date}}</div>
											<small> <i class="mdi mdi-clock-in"></i> <span class="d-none">{{ timerCount }} </span>
											{{lets_start_time(bt.blockTime) }}</small>
											
										</div> -->
										
										<div class="d-flex w-100 justify-content-between cursor-pointer" @click="$router.push('/my-booking/details/'+bt.booking_id)">
											<h5 class="mb-0">{{bt.billing_first_name}} </h5>
											<span class="d-none">{{ timerCount }} </span>
											<div class="color-venue"><i class="mdi mdi-clock mdi-spin"></i> {{lets_start_time(bt.blockTime) }}</div>
										</div>
										<div class="font-weight-bold">{{bt.child_venue_name}}</div>
										<div class="font-weight-bold">Event Date : {{date_format(bt.start_date)}}</div>
										<small>{{bt.addedTime}}</small>
										
									</b-list-group-item>
									<b-list-group-item href="#" v-if="!Block_details">
										
										<h5 class="m-0 card-title">No Blocking Found</h5>
										
										
									</b-list-group-item>
									
									
									
								</b-list-group>
								
								
								<!--    <div id="chart">
									<vue-apex-charts type="radialBar" height="150" :options="chartOptions2" :series="series2"></vue-apex-charts>
								</div> -->
							</div>
						</div>
					</div>
					
				</div>
			</div>
			<div class="col-12 col-xl-8 mb-4">
				<div class="card">
					<div class="card-body p-0">
						<div class="Drow Drow-bordered g-0">
							<div class="col-md-8 position-relative p-4">
								<div class="card-header d-inline-block p-0 text-wrap position-absolute">
									<h5 class="m-0 card-title">Revenue Report</h5>
									
								</div>
								<div id="chart">
									<!-- <vue-apex-charts  ref="booking_charts" type="bar" height="350" :options="chartOptions4" :series="series4"></vue-apex-charts><vue-apex-charts  ref="booking_charts" type="bar" height="350" :options="chartOptions4" :series="series4"></vue-apex-charts> -->
									<vue-apex-charts   ref="booking_charts" type="bar" height="350" :options="chartOptions_" :series="series_"></vue-apex-charts>
								</div>
							</div>
							<div class="col-md-4 p-4">
								<b-list-group>
									<b-list-group-item href="#" active class="list-group-header">
										
										<h5 class="m-0 card-title">Last Booking</h5>
										
										
									</b-list-group-item>
									
									
									
									<b-list-group-item href="#"  class="flex-column align-items-start" v-for="(last ,las) in last_transaction" :key="las" @click="$router.push('/my-booking/details/'+last.booking_id)">
										<div class="d-flex w-100 justify-content-between  cursor-pointer" >
											<h5 class="mb-0">{{last.billing_first_name}} </h5>
											
										</div>
										<div class="font-weight-bold">{{last.child_venue_name}}</div>
										<div class="font-weight-bold">Event Date : {{date_format(last.from_date)}}</div>
										<small>{{last.addedTime}}</small>
									</b-list-group-item>
									
									
								</b-list-group>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- <div class="col-md-6 col-xl-4 mb-4">
				<div class="card h-100">
				<div class="card-header d-flex justify-content-between">
				<div class="card-title m-0 me-2">
				<h5 class="m-0 me-2">Venue Transactions</h5>
				<small class="text-muted">Total 58 Transactions done in this Month</small>
				</div>
				<div class="dropdown">
				<button class="btn p-0" type="button" id="transactionID" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
				<i class="ti ti-dots-vertical ti-sm text-muted"></i>
				</button>
				<div class="dropdown-menu dropdown-menu-end" aria-labelledby="transactionID">
				<a class="dropdown-item" href="javascript:void(0);">Last 28 Days</a>
				<a class="dropdown-item" href="javascript:void(0);">Last Month</a>
				<a class="dropdown-item" href="javascript:void(0);">Last Year</a>
				</div>
				</div>
				</div>
				<div class="card-body">
				<ul class="p-0 m-0">
				<li class="d-flex mb-3 pb-1 align-items-center">
				<div class="badge bg-label-primary me-3 rounded p-2">
				<i class="ti ti-wallet ti-sm"></i>
				</div>
				<div class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
				<div class="me-2">
                <h6 class="mb-0">Wallet</h6>
                <small class="text-muted d-block">Starbucks</small>
				</div>
				<div class="user-progress d-flex align-items-center gap-1">
                <h6 class="mb-0 text-danger">-$75</h6>
				</div>
				</div>
				</li>
				<li class="d-flex mb-3 pb-1 align-items-center">
				<div class="badge bg-label-success rountrded me-3 p-2">
				<i class="ti ti-browser-check ti-sm"></i>
				</div>
				<div class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
				<div class="me-2">
                <h6 class="mb-0">Bank Transfer</h6>
                <small class="text-muted d-block">Add Money</small>
				</div>
				<div class="user-progress d-flex align-items-center gap-1">
                <h6 class="mb-0 text-success">+$480</h6>
				</div>
				</div>
				</li>
				<li class="d-flex mb-3 pb-1 align-items-center">
				<div class="badge bg-label-danger rounded me-3 p-2">
				<i class="ti ti-brand-paypal ti-sm"></i>
				</div>
				<div class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
				<div class="me-2">
                <h6 class="mb-0">Paypal</h6>
                <small class="text-muted d-block mb-1">Client Payment</small>
				</div>
				<div class="user-progress d-flex align-items-center gap-1">
                <h6 class="mb-0 text-success">+$268</h6>
				</div>
				</div>
				</li>
				<li class="d-flex mb-3 pb-1 align-items-center">
				<div class="badge bg-label-secondary me-3 rounded p-2">
				<i class="ti ti-credit-card ti-sm"></i>
				</div>
				<div class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
				<div class="me-2">
                <h6 class="mb-0">Master Card</h6>
                <small class="text-muted d-block mb-1">Ordered iPhone 13</small>
				</div>
				<div class="user-progress d-flex align-items-center gap-1">
                <h6 class="mb-0 text-danger">-$699</h6>
				</div>
				</div>
				</li>
				<li class="d-flex mb-3 pb-1 align-items-center">
				<div class="badge bg-label-info me-3 rounded p-2">
				<i class="ti ti-currency-dollar ti-sm"></i>
				</div>
				<div class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
				<div class="me-2">
                <h6 class="mb-0">Bank Transactions</h6>
                <small class="text-muted d-block mb-1">Refund</small>
				</div>
				<div class="user-progress d-flex align-items-center gap-1">
                <h6 class="mb-0 text-success">+$98</h6>
				</div>
				</div>
				</li>
				<li class="d-flex mb-3 pb-1 align-items-center">
				<div class="badge bg-label-danger me-3 rounded p-2">
				<i class="ti ti-brand-paypal ti-sm"></i>
				</div>
				<div class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
				<div class="me-2">
                <h6 class="mb-0">Paypal</h6>
                <small class="text-muted d-block mb-1">Client Payment</small>
				</div>
				<div class="user-progress d-flex align-items-center gap-1">
                <h6 class="mb-0 text-success">+$126</h6>
				</div>
				</div>
				</li>
				<li class="d-flex align-items-center">
				<div class="badge bg-label-success me-3 rounded p-2">
				<i class="ti ti-browser-check ti-sm"></i>
				</div>
				<div class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
				<div class="me-2">
                <h6 class="mb-0">Bank Transfer</h6>
                <small class="text-muted d-block mb-1">Pay Office Rent</small>
				</div>
				<div class="user-progress d-flex align-items-center gap-1">
                <h6 class="mb-0 text-danger">-$1290</h6>
				</div>
				</div>
				</li>
				</ul>
				</div>
				</div>
			</div> -->
			<div class="col-xl-6  mb-4">
				<div class="card h-100">
					<div class="card-header d-flex justify-content-between">
						<h5 class="card-title m-0 me-2">Upcoming Bookings</h5>
						<div class="dropdown">
							<button class="btn p-0" type="button" id="teamMemberList" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<i class="ti ti-dots-vertical ti-sm text-muted"></i>
							</button>
							
						</div>
					</div>
					<div class="card-body">
					<b-table 
					:items="get_upcoming_booking"
					:fields="fields"
					responsive
					hover
					bordered
					show-empty
					empty-text="No Data Available"
					style="max-width: 100%"
					thead-class="venue-color"
					@row-clicked="onRowClicked" 
					>
						<template  #cell(price)="data">
							
							<span v-html="currency_check(numberWithCommas(data.item.price))"></span>
						</template>
					</b-table>
				</div>
				</div>
				
			</div> 
			
			<div class="col-xl-6  mb-4">
				<div class="card h-100">
					<div class="card-header d-flex justify-content-between">
						<h5 class="card-title ">Upcoming AddOns Bookings</h5>
						
					</div>
					<div class="card-body">
					<b-table
					:items="get_upcoming_booking"
					:fields="fields1"
					responsive
					bordered
					hover
					show-empty
					empty-text="No Data Available"
					style="max-width: 100%"
					thead-class="venue-color"
					>
						<template #cell(addon)="data">
							
							<span v-for="(add,i) in data.item.addon" :key="i" >
								<span v-if="add.add_on_name!='null'">
									<i v-if="add.add_on_name!=''"> {{i+1}} {{add.add_on_name}}</i> 
									<br>
								</span> 
							</span> 
							<span v-if="!data.item.addon" >
								<i> No Add-On</i>
							</span> 
						</template> 
						<!-- <template #cell(ament)="data">
							<span v-for="(am,i) in data.item.ament" :key="i" >
							<i v-if="am.name!=''"> {{i+1}} {{am.name}}</i> <br>
							</span>
						</template> -->
					</b-table>
					<b-row align-v="center">
						<!--  <b-col cols="4">
							<span
							>Showing <b>{{ totalRows }}</b> out of
							<b>{{ totalData }}</b> entries.</span
							>
							</b-col>
							<b-col cols="8">
							<b-pagination
							v-model="page"
							:total-rows="count"
							:per-page="pageSize"
							@change="handlePageChange"
							prev-text="Prev"
							next-text="Next"
							align="right"
							></b-pagination>
						</b-col> -->
					</b-row>
				</div>
				</div>
			</div>
			
			<div class="col-lg-4 col-md-12">
				<div class="card " style="height: 511px;">
					<div class="card-header d-flex justify-content-between">
						<h5 class="card-title m-0 me-2"> Event Type</h5>
						<hr>
					</div>
					<div class="card-body h-75" >
						<vue-apex-charts type="pie"  :options="chartOptions1" :series="series1" width="400" align="right"></vue-apex-charts>
						
					</div>
				</div>
			</div>
			
			<div class="col-lg-4 mb-4 mb-lg-0">
				<div class="card h-100">
					<div class="card-header d-flex justify-content-between">
						<h5 class="card-title m-0 me-2"> Upcoming Payment</h5>
						
					</div>
					<div class="table-responsive">
						<table class="table table-borderless border-top">
							<thead class="border-bottom venue-color" >
								<tr>
									<th>Invoice Number</th>
									<th>Month</th>
									<th>Amount</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(ub,b) in booking_count.vb_invoice" :key="b" :class="ub.status==1 ? 'bg-paid':''">
									<td>
										<div class="d-flex justify-content-start align-items-center">
											<div class="me-3">
												
											</div>
											<div class="d-flex flex-column">
												<p class="mb-0 fw-medium">{{ ub.invoice_number }}</p>
											</div>
										</div>
									</td>
									
									<td>{{ date_format(ub.first_date) }}</td>
									<td class="text-right">
										<p class="mb-0 fw-medium">
										<span v-html="currency_check(numberWithCommas(Math.round(ub.vb_amount)))"></span></p>
									</td>
								</tr>
								
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<div class="col-lg-4 col-md-12">
				<div class="card ">
					<div class="card-header d-flex justify-content-between">
						<h5 class="card-title m-0 me-2 pt-1 mb-2 d-flex align-items-center"><i class="ti ti-list-details ms-n1 me-2"></i> Activity Logs</h5>
						
					</div>
					<div class="card-body pb-0" style="height: 430px;">
						<ul class="timeline ms-1 mb-0">
							
							<li class="timeline-item timeline-item-transparent ps-4" v-for="(logs,li) in Activity_logs" :key="li">
								<span class="timeline-point "></span>
								<div class="timeline-event">
									<div class="timeline-header">
										<h6 class="mb-0">{{ JSON.parse(logs.description).message}}</h6>
										
									</div>
									<small class="text-muted">{{logs.log_time}}</small>
									<!--  <p class="mb-2">{{ JSON.parse(logs.description).message}}</p> -->
									
								</div>
							</li>
							
						</ul>
					</div>
				</div>
			</div>
		
		</b-row>
	
		
		<b-modal id="modal-centers" centered  ref="welcome" hide-header hide-footer size="lg" class="welcome-message-screeen" @click="closeModal()"> 
			
			<!--  <h3 class="my-4">Hi! {{dateTime()}} {{this.user_name}}</h3>-->
			<center  @click="closeModal()"> <h1 :show="!show">Welcome Back  </h1> 
			<!-- <img :src="require('@/assets/images/venue_booking/logo.svg')" alt="homepage" class="w-25 mx-auto" > -->
				<!-- <img :src="logo" alt="homepage" > -->
				<!--    <b-img-lazy 
				:src="getImageUrl()" alt="Image 1"></b-img-lazy> -->
	
			<img 
				:src="user_app_logo" />  
				
			
							</center>
						
		</b-modal>
		
		
		
	</div>
</template>
<script>
	// -----------------------------------------
	// Import Components Here
	// -----------------------------------------
	
	// -----------------------------------------
	// Export Here
	// -----------------------------------------
	import VueApexCharts from "vue-apexcharts";
	import Multiselect from "vue-multiselect";
  import global from '../../mixins/global'; 
  import { mapState } from "vuex";
	import axios from "axios";
	export default {
		name: "Dashboard",
		mixins:[global],
		data: () => ({
			title: "Dashboard",
			backgroundImage: 'https://vuejsfeed.com/img/vuejsfeed-medium.png',
      loadingImage: 'http://i.imgur.com/5XiyiYw.jpg',
			is_first_login:false,
			filterEnable:false,
			parent_option:[],
			child_option:[],
			Block_details:[],
			Activity_logs:[],
			last_transaction:[],
			selectedItem: null,
			parent_selected:'',
			child_selected:'',
			curncy:'',
			user_name:'',
			role_id:'',
			select_mode:'',
			timerCount: 0,
			page: 1,
			show: false,
			countDownToTime : new Date("Sep 26, 2029 00:00:00").getTime(),
			count: 0,
			totalData: "",
			totalRows: "",
			loadImage:localStorage.getItem('loadImage')?localStorage.getItem('loadImage') :'' ,
			logo:"require('@/assets/images/venue_booking/logo.svg')",
			mainProps: {
          center: true,
          fluidGrow: true,
          blank: true,
          blankColor: '#bbb',
          width: 600,
          height: 400,
          class: 'my-5'
        },
			/* Revenue Chart */
			series4: [{
				name: 'Cash Flow',
				data: []
			}],
			chartOptions4: {
				chart: {
					type: 'bar',
					height: 500
				},
				plotOptions: {
					bar: {
						colors: {
							ranges: [{
								from: -100,
								to: -46,
								color: '#7367f0' //FEB019
								}, {
								from: -45,
								to: 0,
								color: '#7367f0'
							}]
						},
						columnWidth: '25%',
					}
				},
				dataLabels: {
					enabled: false,
				},
				yaxis: {
					title: {
						// text: 'Growth',
					},
					labels: {
						formatter: function (y) {
							return "Rs. " + y.toFixed(0) ;
						}
					}
				},
				xaxis: {
					type: 'datetime',
					categories: [],
					labels: {
						rotate: -90
					}
				}
			},
			series1: [],

			chartOptions1: {
				/* chart: {
					width: 380,
					type: 'pie',
				},
				labels: [],
				responsive: [{
					breakpoint: 480,
					options: {
						chart: {
							width: 200
						},
						legend: {
							position: "top",
        horizontalAlign: "top",
        offsetX: -15,
        fontWeight: "bold",
						}
					}
				}] */
				chart: {
              width: '100%',
              type: 'pie',
            },
            labels: [],
			colors:['#FFC300', '#FF0000', '#B2FF00', '#088938', '#0AF2F6', '#0A57F6', '#0716A7', '#F10606', '#F10667', '#F106A4', '#E706F1', '#BC06F1', '#9F06F1','#412FE3', '#5006F1'],
            plotOptions: {
              pie: {
                dataLabels: {
                  offset: -5,
				
                }
              }
            },
            title: {
             // text: "Monochrome Pie"
            },
            dataLabels: {
				
              /* formatter(val, opts) {
                const name = opts.w.globals.labels[opts.seriesIndex]
                return [name, val.toFixed(1) + '%']
              } */
            },
            legend: {
              show: true,
			position: "bottom",
			horizontalAlign: "left",
        offsetX: -10,
        fontWeight: "bold",
            }
			},
			fields: [
			{
				key: "refno",
				label: "Ref. No.",
				sortable: true,
			},
			{
				key: "date",
				label: "Booking Date",
				sortable: true,
			},
			{
				key: "customer_name",
				label: "Customer Name",
				sortable: true,
			},
			{
				key: "price",
				label: "Price",
				sortable: true,
			},
		
			],
			fields1: [
			{
				key: "refno",
				label: "Ref. No.",
				sortable: true,
			},
			{
				key: "venue_name",
				label: "Venue Name",
				sortable: true,
			},
			{
				key: "date",
				label: "Booking date",
				sortable: true,
			},
			{
				key: "addon",
				label: "Add-Ons",
				sortable: true,
			},
		
			],
			booking_count: [],
			get_recent_booking: [],
			get_upcoming_booking: [],
			pie_series: [44, 55, 41, 17, 15],
			name_arry: [],
			allcounts:0,
			Booking_refno :'',
			errormsg:'',

			series_: [{
            name: 'Net Profit',
            data: [] //44, 55, 57, 56, 61, 58, 63, 60, 66, 0, 0, 0
          }, /* {
            name: 'Revenue',
            data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
          }, {
            name: 'Free Cash Flow',
            data: [35, 41, 36, 26, 45, 48, 52, 53, 41]
          } */],
          chartOptions_: {
            chart: {
              type: 'bar',
              height: 350
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '50%',
                endingShape: 'rounded'
              },
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              show: true,
              width: 3,
              colors: ['transparent']
            },
            xaxis: {
              categories: [],
            },
            yaxis: {
              title: {
                text: 'Rs. (thousands)'
              }
            },
            fill: {
              opacity: 10
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return "Rs. " + val + " thousands"
                }
              }
            }
          },
		}),
		components: {
			VueApexCharts,
			Multiselect,
			
		},
		methods: {
			getImageUrl(imageId) {
       // const { width, height } = this.mainProps
		imageId
        return this.logo;
      },
	onRowClicked(item) {
      this.selectedItem = item
    },
			getBookingCount() {
				axios.post("/api/get_vendor_dashboard_count",{
					parent_venue_id:this.parent_selected.value,
					child_venue_id:this.child_selected.value,
					mode:this.select_mode,
					}).then((resp) => {
					if (resp.data.status_code == 200) {
						if (resp.data.status) {
							this.booking_count = resp.data.total_counts;
							this.allcounts = parseInt(this.booking_count.total_confimed_booking)+ parseInt(this.booking_count.total_blocked_booking)+ parseInt(this.booking_count.total_draft_booking) + parseInt(this.booking_count.total_quot_booking)
							
						}
					}
				});
			},
			getUserDetails() {
				axios.get("/api/get_profile").then((resp) => {
					if (resp.data.status_code == 200 && resp.data.status) {
						this.$store.commit("SET_KYC_NOTIFICATION", resp.data.users);
            this.curncy= resp.data.users.currencys;
						this.user_name= resp.data.users.first_name+ ' ' + resp.data.users.last_name;
					
					
						this.logo= process.env.VUE_APP_APIURL+''+resp.data.users.logo;
						this.loadImage= process.env.VUE_APP_APIURL+''+resp.data.users.logo;
						localStorage.setItem('loadImage',this.logo);
						this.show= false;
						
						if(resp.data.users.is_first_login == null){
							this.is_first_login = true;
							
							axios.post("/api/update_first_login").then(() => {
								// console.log(resp);
							});
						}
					}
				});
/* First Login Welcome Message */
        if(localStorage.getItem("welcome_message")=='Yes')
				{
					this.$refs['welcome'].show();
				}
				setTimeout(() => localStorage.removeItem("welcome_message"),
				this.closeModal()
			
				, 1000);
				
/* END First Login Welcome Message */
			},
			getBookingList() {
				axios.post("/api/get_vendor_bookings").then((resp) => {
					if (resp.data.status_code == 200) {
						this.get_recent_booking = [];
						this.get_upcoming_booking = [];
						this.pie_series = [];
						if (resp.data.status) {
							// for incoming
							
							this.Activity_logs=resp.data.bookings.Activity_logs;
							this.last_transaction=resp.data.bookings.last_transaction;
							for (let k = 0; k < resp.data.bookings.pie.pie_array.length; k++) 
							{
								this.series_[0].data.push(resp.data.bookings.pie.pie_array[k]);
								this.series1.push(resp.data.bookings.pie.pie_array[k]);
								this.chartOptions1.labels.push(resp.data.bookings.pie.name_arry[k]);
							}
							
							
							for (let l = 0; l < resp.data.bookings.getMonthlyData.month.length; l++) 
							{
								this.$refs.booking_charts.refresh(
								( this.chartOptions_.xaxis.categories.push(resp.data.bookings.getMonthlyData.month[l])),
								( this.series_[0].data.push(resp.data.bookings.getMonthlyData.amount[l]))
								);
								
							}
							
							
							this.Block_details=resp.data.bookings.Block_details;
							
							this.count = this.totalData = resp.data.total_records;
							this.totalRows = resp.data.bookings.get_upcoming_booking.bookings.length;
							
							for (
							var i = 0;
							i < resp.data.bookings.get_recent_booking.length;
							i++
							) {
								this.get_recent_booking.push({
									date:
									resp.data.bookings.get_recent_booking[i].from_date,
									booked_on: resp.data.bookings.get_recent_booking[i].booked_on,
									refno: resp.data.bookings.get_recent_booking[i].booking_auto_id,
								
									venue_name:
									resp.data.bookings.get_recent_booking[i].child_venue.child_venue_name.substring(0,25),
									price:
									"Rs. " +resp.data.bookings.get_recent_booking[i].total_booking_price,
								
								});
							}
							
							// for upcoming
							
							for (
							var a = 0;
							a < resp.data.bookings.get_upcoming_booking.bookings.length;
							a++
							) {
								
								this.get_upcoming_booking.push({
									addon:
									resp.data.bookings.get_upcoming_booking.bookings[a].addOns? resp.data.bookings.get_upcoming_booking.bookings[a].addOns : '', 
									date:
									resp.data.bookings.get_upcoming_booking.bookings[a].from_date ,
									refno: resp.data.bookings.get_upcoming_booking.bookings[a].booking_auto_id,
									customer_name: resp.data.bookings.get_upcoming_booking.bookings[a].billing_first_name,
								venue_name:resp.data.bookings.get_upcoming_booking.bookings[a].child_venue.child_venue_name.substring(0,25),
									price:resp.data.bookings.get_upcoming_booking.bookings[a].total_booking_price,  
									ament:resp.data.bookings.get_upcoming_booking.bookings[a].amenties,
								});
							}
						}
					}
				});
			},
			
			
			filterClick()
			{
				this.filterEnable=!this.filterEnable;
				}, parentVenueList() {
				axios.get("/api/parent_venue_listing").then((resp) => {
					if (resp.data.status_code == 200) {
						if (resp.data.status) {
							for (var i = 0; i < resp.data.parent_venue_details.length; i++) {
								this.parent_option.push({
									value: resp.data.parent_venue_details[i].parent_venue_id,
									name: resp.data.parent_venue_details[i].venue_name,
								});
							}
						}
					}
				});
			},
			callChildVenue() {
				if (this.parent_selected === "" || this.parent_selected === null) {
					this.child_option = [];
					} else {
					var child_id = this.parent_selected.value;
					axios
					.post("/api/child_venue_listing", {
						parent_venue_id: child_id,
					})
					.then((resp) => {
						if (resp.data.status_code == 200) {
							if (resp.data.status) {
								this.child_option = [];
								this.child_selected = "";
								for (var i = 0; i < resp.data.child_venue_details.length; i++) {
									this.child_option.push({
										value: resp.data.child_venue_details[i].child_venue_id,
										name: resp.data.child_venue_details[i].child_venue_name,
									});
								}
								} else {
								this.child_option = [];
							}
						}
					});
				}
			},
			currency_check(amt) {
				return '<i class="mdi '+this.curncy+'"></i>'+amt;
			},
		
			closeModal()
			{
				this.$refs['welcome'].hide();
			},
			filterCheck()
			{
				this.getBookingCount();
				this.filterEnable=false;
			},
			//this.pie_series
			pie_function()
			{
				//return this.pie_series;
			},
			numberWithCommas(x) {
           /*  if(x)
            {
                return x.toString().split('.')[0].length > 3 ? x.toString().substring(0,x.toString().split('.')[0].length-3).replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + x.toString().substring(x.toString().split('.')[0].length-3): x.toString();
            }
            else
            {
                return 0;
            } */
            var num = x ? x : 0;
				return num.toLocaleString('en-IN')
        },
      lets_start_time:function(todatetime)
        {
            const blockedTime = new Date().getTime();
            const blockTime = new Date(todatetime).getTime();
            const timeDifference = blockTime - blockedTime;
            const millisecondsInOneSecond = 1000;
            const millisecondsInOneMinute = millisecondsInOneSecond * 60;
            const millisecondsInOneHour = millisecondsInOneMinute * 60;
            const millisecondsInOneDay = millisecondsInOneHour * 24;
            const differenceInDays = timeDifference / millisecondsInOneDay;
            const remainderDifferenceInHours = (timeDifference % millisecondsInOneDay) / millisecondsInOneHour;
            const remainderDifferenceInMinutes = (timeDifference % millisecondsInOneHour) / millisecondsInOneMinute;
            const remainderDifferenceInSeconds = (timeDifference % millisecondsInOneMinute) / millisecondsInOneSecond;
            
            const remainingDays = Math.floor(differenceInDays);
            const remainingHours = Math.floor(remainderDifferenceInHours);
            const remainingMinutes = Math.floor(remainderDifferenceInMinutes);
            const remainingSeconds =Math.floor(remainderDifferenceInSeconds);
            
            var timerCounter=0;
            if(remainingDays!=0)
            {
                var thr=remainingDays*24;
                var timg = parseInt(remainingHours)+parseInt(thr);
                timerCounter=timg +":"+ remainingMinutes +":"+ remainingSeconds;
            }
            else
            {
                timerCounter=remainingHours +":"+ remainingMinutes +":"+ remainingSeconds;
            }
           /*  if(remainingMinutes==0)
            {
               
            }
            else if(remainingSeconds==0)
            {
               
            } */
            if(timerCounter<="0:0:0")
            {
                this.blocktimeUpdate();
            }
            
            return timerCounter;
        },
        blocktimeUpdate() {
          axios.get("/api/autocallCalender").then((resp) => {
         resp
       });
   },
			target_selectChild()
			{
				this.errormsg=false;
				if(this.Booking_refno!="")
				{
					this.$router.push("/finance/search_logs/" + this.Booking_refno);
				}
				else
				{
					this.errormsg=true;
				}
			},
      zero_add(length) {
        length = length || 2; 
        return (new Array(length).join('0')+this).slice(length*-1);
      }
		},
		mounted() {
			document.documentElement.scrollTop = 0;
			//this.getUserDetails();
			//this.getBookingCount();
			//this.getBookingList();
			//this.parentVenueList();
			this.show= true,
			this.logo= process.env.VUE_APP_APIURL+'/'+this.set_user_data.log;
			//set_user_data.log
			setInterval(() => { this.startTimer() }, 1000);
			setTimeout(() => 
				this.closeModal()
				, 2000); 
			},
			computed: {
    ...mapState(['set_user_data','user_app_logo']),
   },
		watch: {
			
			timerCount: {
				handler(value) {
					
					if (value > 0) {
						setTimeout(() => {
							this.lets_start_time();
						}, 1000);
					}
				},
				immediate: true 
			}
			
		}
	};

	
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style >
	#money_l {
    font-size: 16px;
	}
	#dount_set {
    padding-top: 20px;
    padding-bottom: 20px;
	}
	@media screen and (max-width: 767px) {
    .border-bottom {
	border-bottom: none !important;
    }
	}
	@media screen and (max-width: 576px) {
    .border-bottom {
	border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
    }
	}
	.fs-36
	{
    font-size: 36px;
	}
	
	.enter { transform: translateX(100%) }
	.enter-to { transform: translateX(0) }
	.slide-enter-active { position: absolute }
	
	.leave { transform: translateX(0) }
	.leave-to { transform: translateX(-100%) }
	
	.slide-enter-active,
	.slide-leave-active { transition: all 750ms ease-in-out }
	
	.modal-backdrop.in {
	opacity: 0.5;
	filter: alpha(opacity=50);
	}
	.card-design
	{
	background-image: url('../../assets/images/dashboardlogo.png');
	color: white;
	}
	.display-4
	{
	font-size:2.5rem !important
	}
	.fs-10
	{
	font-size: 10px !important;
	}
	.list-group-header
	{
	background-image: linear-gradient(242deg, #A44BF3 0%, #499CE8 100%) !important;
	border:none !important;
	}
	#modal-centers___BV_modal_outer_ > .modal-backdrop {
    opacity: unset !important;
	}
	#modal-centers___BV_modal_outer_ .modal-backdrop {
    background-color: #fdfeff !important;
	}
	.color-venue
	{
		color: #A44BF3 !important;
	}
	.divider.divider-vertical {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: unset;
}
.bg-paid
{
	background-color: #dcfbe0 !important;
}
</style>