<template>
  <div>
    <b-row>
            <b-col lg="6" md="6" sm="6" class="heading-title">
                <h4>Leads Dashboard  </h4>
                <b-breadcrumb class="p-0 m-0" id="breadcrumb-heading">
                  <b-breadcrumb-item to="/dashboard" custom> Home </b-breadcrumb-item>
                  <b-breadcrumb-item active>Dashboard</b-breadcrumb-item>
                </b-breadcrumb>
            </b-col>
            <b-col lg="6" md="6" sm="6" class="btns-header">
                <!-- <div class="text-right mb-1 cursor-pointer" @click="filterClick()"><i class="mdi mdi-filter"></i> </div> -->
            </b-col>
        </b-row>

<!--       <div class="col-xl-4 mb-4 col-lg-4 col-12">
                <div class="card card-design" >
                    <div class=" Drow">
                        <div class="col-12">
                            <div class="card-body text-nowrap">
                <h5 class="card-title mt-4">Hi! {{user_name}}</h5>
                               
                            </div>
                        </div>
                        <div class="col-5 text-center text-sm-left">
                            <div class="card-body pb-0 px-0 px-md-4">
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
             
        <b-row>

          <div class="col-xl-4 mb-4 col-lg-4 col-12">
      
    </div>
    <div class="col-xl-8 mb-4 col-lg-8 col-12">
      
    </div>
    <b-col lg="9" cols="9" md="12">
      <!-- <b-card class="mb-4 w-100">
        <div class=" col-md-12 col-sm-12" style="border:0px solid">
  <h3 class="d-flex justify-content-between" style="padding:2px; margin-bottom:2px;">
    <div><i class="mdi mdi-calendar"></i> Expected Launch Date </div>
    
  </h3>
  <div class="col-md-12" style="padding:0px;">
    <br> -->
    
     <!--  <table class="table table-bordered table-style">
        <thead class="theadfixed">
        <tr>
          <th colspan="1" class="text-right">
            <a href="javascript:void(0)" @click="front_calendar('Pre')"><span class="mdi mdi-arrow-left"></span></a>
          </th>
          <th colspan="2">  <datepicker
          minimum-view="month"
          id="datePickerIds"
          format="MMMM-yyyy"
          placeholder="Enter built year"
          v-model="month_year"
          class="form-control custom_date_picker extraline text-center"
          @input="front_calendar('Nrl')"
          
          >
          </datepicker> </th>
          <th colspan="1">
            <a href="javascript:void(0)" @click="front_calendar('Nxt')"><span class="mdi mdi-arrow-right"></span></a>
          </th>
        </tr>

    <tr class="tr_colors">
      <td class="text-center" width="25%"><img :src="require('@/assets/images/hot-icon.svg')" style="width: 12px;"> Hot </td>
      <td class="text-center" width="25%"><img :src="require('@/assets/images/1413121417.svg')" style="width: 12px;"> Cold </td>
      <td class="text-center" width="25%"><img :src="require('@/assets/images/umbrella-with-rain-drops.svg')" style="width: 19px;"> Drizzle </td>
      <td class="text-center" width="25%"><img :src="require('@/assets/images/Exclamation_Point.svg')" style="width: 7px;"> Surprise</td>
    </tr>
    <tr v-for="(group_date,gd) in groupof_date.groupof_date" :key="gd">
        <td>{{ groupof_date.expected_date[group_date.expected_date][1] ? groupof_date.expected_date[group_date.expected_date][1].name + ' &nbsp; ' + date_format(groupof_date.expected_date[group_date.expected_date][1].expected_date) : ''   }}</td>
        <td>{{ groupof_date.expected_date[group_date.expected_date][2] ? groupof_date.expected_date[group_date.expected_date][2].name + ' &nbsp;' + date_format(groupof_date.expected_date[group_date.expected_date][2].expected_date): ''   }}</td>
        <td>{{ groupof_date.expected_date[group_date.expected_date][3] ? groupof_date.expected_date[group_date.expected_date][3].name + ' &nbsp; ' + date_format(groupof_date.expected_date[group_date.expected_date][3].expected_date): ''   }}</td>
        <td>{{ groupof_date.expected_date[group_date.expected_date][0] ? groupof_date.expected_date[group_date.expected_date][0].name + ' &nbsp; ' + date_format(groupof_date.expected_date[group_date.expected_date][0].expected_date): ''   }}</td>
       
    </tr> -->

   
   <!--  <tr v-if="groupof_date.groupof_date.length<4">
      <td><br></td>
      <td></td>
      <td></td>
      <td></td>

    </tr>
    <tr v-if="groupof_date.groupof_date.length<3">
      <td><br></td>
      <td></td>
      <td></td>
      <td></td>

    </tr>
    <tr v-if="groupof_date.groupof_date.length<2">
      <td><br></td>
      <td></td>
      <td></td>
      <td></td>

    </tr>
    <tr v-if="groupof_date.groupof_date.length<1">
      <td><br></td>
      <td></td>
      <td></td>
      <td></td>

    </tr> -->
    <!-- <tr>
      <td style="height:225px">
        <b-list-group>
<b-list-group-item  v-for="(fire, f) in  fire_list" :key="f" class="d-flex justify-content-between align-items-center">
  
  <span>{{ date_format(fire.expected_date) }} &nbsp;  &nbsp;</span>
  <span>
    <router-link :to="'/Sales/view_sales_details/' + fire.id" >
          {{fire.name }}
          </router-link>
        </span>
</b-list-group-item>

</b-list-group>
      </td>
      <td>
        <b-list-group>
<b-list-group-item  v-for="(water, w) in  water_list" :key="w" class="d-flex justify-content-between align-items-center">
  
  <span>{{ date_format(water.expected_date) }} &nbsp;  &nbsp;</span>
  <span><router-link :to="'/Sales/view_sales_details/' + water.id" >
          {{water.name }}
          </router-link></span>
</b-list-group-item>

</b-list-group>
      </td>
      <td>
        <b-list-group>
<b-list-group-item  v-for="(dizzer, d) in  dizzers_list" :key="d" class="d-flex justify-content-between align-items-center">
  
  <span>{{ date_format(dizzer.expected_date) }} &nbsp;  &nbsp; </span>
  <span><router-link :to="'/Sales/view_sales_details/' + dizzer.id" >
          {{dizzer.name }}
          </router-link>
        </span>
</b-list-group-item>

</b-list-group>
      </td>
      <td>
        <b-list-group>
<b-list-group-item  v-for="(excla, e) in  exclametry" :key="e" class="d-flex justify-content-between align-items-center">
  
  <span>{{ date_format(excla.expected_date) }} &nbsp;  &nbsp;  </span>
  <span><router-link :to="'/Sales/view_sales_details/' + excla.id" >
          {{excla.name }}
          </router-link>
        </span>
</b-list-group-item>

</b-list-group>
      </td>
    </tr> -->
         <!--  <tr>
            <th  v-for="(day,index) in days" :key="index"  class="text-left" :class="day">
              <span class="d-none d-sm-block " >{{ day.short }}</span>
              <span class="d-block d-sm-none " >{{ day.short }}</span>
            </th>
          </tr> -->
      <!--   </thead> -->

      <!--   <tr v-for="(row,key) in 6" :key="key" >
          <td  v-for="(day1,l) in calender" :key="l" :class="day1" >
            <span v-if="day1[key] && day1[key].month_days!=''" class="text-center ">
              {{ day1[key].month_days }} {{ key }}
              <span v-for="(exp,e) in check_calendar_expected_date(day1[key].day_shortname,key)" :key="e">
              {{ exp.name }}
              </span>
            </span> 
          </td>
        </tr> -->
        
       

        <!-- <tr v-for="(day,d) in calender" :key="d">
          <th>{{ day }}</th>
          <th>M</th>
          <th>T</th>
          <th>W</th>
          <th>T</th>
          <th>F</th>
          <th>S</th>
        </tr>
        <tr>
          <td>1</td>
          <td>2</td>
          <td>3</td>
          <td>4</td>
          <td>5</td>
          <td>6</td>
          <td>7</td>
        </tr>
        <tr>
          <td>8</td>
          <td>9</td>
          <td>10</td>
          <td>11</td>
          <td >12</td>
          <td>13</td>
          <td>14</td>
        </tr>
        <tr>
          <td>15</td>
          <td>16</td>
          <td>17</td>
          <td>18</td>
          <td>19</td>
          <td>20</td>
          <td>21</td>
        </tr>
         <tr>
          <td>22</td>
          <td>23</td>
          <td>24</td>
          <td>25</td>
          <td>26</td>
          <td>27</td>
          <td>28</td>
        </tr>
          <tr>
          <td>29</td>
          <td>30</td>
          <td>31</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
         -->
       
     <!--  </table>-->

  <!-- </div>
</div>

      </b-card>  -->

      <b-card class="mb-4 w-100">
        <div class=" col-md-12 col-sm-12" style="border:0px solid">
  <h3 class="d-flex justify-content-between" style="padding:2px; margin-bottom:2px;">
    <div><i class="mdi mdi-calendar"></i> Expected Launch Date </div>
    
  </h3>
  <div class="col-md-12" style="padding:0px;">
    <br>
    
      <table class="table table-bordered table-style">
        <thead class="theadfixed">
        <tr>
          <th colspan="1" class="text-right">
            <a href="javascript:void(0)" @click="front_calendar('Pre')"><span class="mdi mdi-arrow-left"></span></a>
          </th>
          <th colspan="2">  <datepicker
          minimum-view="month"
          id="datePickerIds"
          format="MMMM-yyyy"
          placeholder="Enter built year"
          v-model="month_year"
          class="form-control custom_date_picker extraline text-center"
          @input="front_calendar('Nrl')"
          
          >
          </datepicker> </th>
          <th colspan="1">
            <a href="javascript:void(0)" @click="front_calendar('Nxt')"><span class="mdi mdi-arrow-right"></span></a>
          </th>
        </tr>

    <tr class="tr_colors">
     
      <th class="text-center" width="25%"><img :src="require('@/assets/images/hot-icon.svg')" style="width: 12px;"> Hot </th>
      <th class="text-center" width="25%"><img :src="require('@/assets/images/1413121417.svg')" style="width: 12px;"> Cold </th>
      <th class="text-center" width="25%"><img :src="require('@/assets/images/umbrella-with-rain-drops.svg')" style="width: 19px;"> Drizzle </th>
      <th class="text-center" width="25%"><img :src="require('@/assets/images/Exclamation_Point.svg')" style="width: 7px;"> Surprise</th>
    </tr>
    <tr>
     
      <td class="p-0 align-top">
        <ul class="p-0">
          <li v-for="(fire_li,f) in fire_list" :key="f"  class="underline_remove">
          {{ fire_li.name }} {{ date_format(fire_li.expected_date) }}
          </li>
        </ul>
      </td>

     

      <td class="p-0 align-top">
        <ul class="p-0 ">
          <li v-for="(water_li,w) in water_list" :key="w" class="underline_remove">
          {{ water_li.name }} {{ date_format(water_li.expected_date) }}
          </li>
        </ul>
      </td>
      <td class="p-0 align-top">
        <ul class="p-0">
          <li v-for="(dizzers_li,d) in dizzers_list" :key="d" class="underline_remove">
          {{ dizzers_li.name }} {{ date_format(dizzers_li.expected_date) }}
          </li>
        </ul>
      </td>
      <td class="p-0 align-top">
        <ul class="p-0">
          <li v-for="(exclamet,e) in exclametry" :key="e" class="underline_remove">
          {{ exclamet.name }} {{ date_format(exclamet.expected_date) }}
          </li>
        </ul>
      </td>
    </tr>



    <!--<tr v-for="(group_date,gd) in groupof_date.groupof_dates" :key="gd">
      <td  > 
{{ group_date.name }} {{  group_date.expected_date }}
      </td>-->
        <!-- <td>{{group_date[0].fav}} {{ groupof_date[1] ? groupof_date[1].name + ' &nbsp; ' + date_format(groupof_date[1].expected_date) : ''   }}</td>
        <td>{{ groupof_date[2] ? groupof_date[2].name + ' &nbsp;' + date_format(groupof_date[2].expected_date): ''   }}</td>
        <td>{{ groupof_date[3] ? groupof_date[3].name + ' &nbsp; ' + date_format(groupof_date[3].expected_date): ''   }}</td>
        <td>{{ groupof_date[0] ? groupof_date[0].name + ' &nbsp; ' + date_format(groupof_date[0].expected_date): ''   }}</td> -->
       
    <!-- </tr> -->

   
    <tr v-if="exclametry.length<4 ">
      <td><br></td>
      <td></td>
      <td></td>
      <td></td>

    </tr>
    <tr v-if="dizzers_list.length<4">
      <td><br></td>
      <td></td>
      <td></td>
      <td></td>

    </tr>
    <tr v-if="water_list.length<4">
      <td><br></td>
      <td></td>
      <td></td>
      <td></td>

    </tr>
    <tr v-if="fire_list.length<4">
      <td><br></td>
      <td></td>
      <td></td>
      <td></td>

    </tr> 

        </thead>
       
      </table>

  </div>
</div>

      </b-card>
    </b-col>
    <b-col lg="3" cols="3" md="12">
       <b-card class="mb-2 w-100">
        <h3 class=" mt-1 text-center "> Total Leads</h3>
        <h2 class=" mt-1 text-center ">
                   
                    {{ booking_count.total_vendor_count }}
                  </h2>
      </b-card>
     
      <b-row>
        <b-col lg="6" cols="6" md="12">
          <b-card class="mb-4 w-100 text-center">
          <img :src="require('@/assets/images/hot-icon.svg')" style="width: 50px;">
         <h4 class="mt-2">{{ fires }}</h4>
          </b-card>
        </b-col>
        <b-col lg="6" cols="6" md="12">
          <b-card class="mb-4 w-100 text-center">
          <img :src="require('@/assets/images/1413121417.svg')" style="width: 43px;">
          <h4 class="mt-2">{{ water }}</h4>
          </b-card>
        </b-col>
        <b-col lg="6" cols="6" md="12">
          <b-card class="mb-4 w-100 text-center">
          <img :src="require('@/assets/images/umbrella-with-rain-drops.svg')" style="width: 80px;">
          <h4 class="mt-2">{{ dizers }}</h4>
          </b-card>
        </b-col> 
        <b-col lg="6" cols="6" md="12">
          <b-card class="mb-4 w-100 text-center">
          <img :src="require('@/assets/images/Exclamation_Point.svg')" style="width: 35px;" >
          <h4 class="mt-2">{{ exlamtry }}</h4>
          </b-card>
        </b-col>
      </b-row>
    </b-col>
  <b-col md="12" class="mb-3">
    <div class="card h-100 ">
        <div class="card-header">
         
            <h5 class="card-title mb-0">Statistics</h5>
           
        </div>
        <div class="card-body pt-0 mb-2">
          <b-row>
            <b-col cols="12" lg="3" md="6">
              <div class="d-flex align-items-center cursor-pointer" >
                <div class="mr-2">
                  <span class="display-4 text-info fs-5">
                    <i class="mdi mdi-account-multiple"></i>
                  </span>
                </div>
                <div>
                  <span id="money_l" class="text-dark"> Attempted</span>
                  <h5 class="font-medium mb-0">{{ booking_count.total_stand_count }} <span class="text-success fs-10">0%</span></h5>
                </div>
              </div>
            
            </b-col>
            
            <b-col cols="12" lg="3" md="6">
              <div class="d-flex align-items-center cursor-pointer" >
                <div class="mr-2">
                  <span class="display-4 text-warning">
                    <i class="mdi mdi-account-check"></i>
                  </span>
                </div>
                <div>
                  <span id="money_l" class="text-dark">Contacted</span>
                  <h5 class="font-medium mb-0">{{ booking_count.total_loss_count }} <span class="text-success fs-10">0%</span></h5>
                </div>
              </div>
              
            </b-col>
            <b-col cols="12" lg="3" md="6">
              <div class="d-flex align-items-center cursor-pointer" >
                <div class="mr-2">
                  <span class="display-4 text-success">
                    <i class="mdi mdi-account-plus"></i>
                  </span>
                </div>
                <div>
                  <span id="money_l" class="text-dark">Converted</span>
                  <h5 class="font-medium mb-0">{{ booking_count.total_vwin_count }} <span class="text-success fs-10">0%</span></h5>
                </div>
              </div>
            
            </b-col>
            <b-col cols="12" lg="3" md="6">
              <div class="d-flex align-items-center cursor-pointer" >
                <div class="mr-2">
                  <span class="display-4 text-danger">
                    <i class="mdi mdi-account-remove"></i>
                  </span>
                </div>
                <div>
                  <span id="money_l" class="text-dark">Cancelled</span> 
                  <h5 class="font-medium mb-0">{{ booking_count.total_cancel_count }} <span class="text-success fs-10">0%</span></h5>
                  
                </div>
              </div>
            
            </b-col>
          </b-row>
          
        </div>
      </div>
</b-col>
        
    <div class="col-lg-6 mb-4 mb-lg-0">
      <div class="card h-100">
        <div class="card-header d-flex justify-content-between">
          <h5 class="card-title m-0 me-2"> Upcoming Appoinment</h5>
          
        </div>
        <div class="table-responsive">
          <table class="table table-bordered border-top">
            <thead class="border-bottom venue-color" >
              <tr>
                <th>Vendor name</th>
                <th>Mobile </th>
                <th>Next Appoinment Date</th>
                <th>Updates</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(ue,e) in booking_count.upcoming_meeting_sales" :key="e">

                <td>
                  <p class="mb-0 fw-medium">
                  <router-link :to="'/Sales/view_sales_details/' + ue.id" >
          {{ue.name }}
          </router-link>
          </p>
                </td>
                <td>

                  <span v-if="ue.mob && ue.country_code!='+91'">
                    {{  ue.mob.substring(0, 4) }} {{  ue.mob.substring(4, 11) }} 
                  </span>
                  <span v-else>
                    {{ ue.country_code }} {{  ue.mob }} 
                  </span>
                </td>
                <td>
                  <div class="d-flex justify-content-start align-items-center">
                    <div class="me-3">
                      
                    </div>
                    <div class="d-flex flex-column">
                      <p class="mb-0 fw-medium">{{ date_format(ue.appoinment_date) }}</p>
                    </div>
                  </div>
                </td>
                
                
                <td>{{ ue.appoinment }}</td>
                
              </tr>
              
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-lg-6 mb-4 mb-lg-0">
				<div class="card h-100">
					<div class="card-header d-flex justify-content-between">
						<h5 class="card-title m-0 me-2">  Last Appoinment </h5>
						
					</div>
					<div class="table-responsive">
            
						<table class="table table-bordered border-top">
							<thead class="border-bottom venue-color" >
								<tr>
									<th>Vendor name</th>
									<th>Mobile </th>
									<th>Last Appoinment Date</th>
									<th>Updates</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(ue,e) in booking_count.appoinment_before_today" :key="e">

                  <td>
                    <p class="mb-0 fw-medium">
                    <router-link :to="'/Sales/view_sales_details/' + ue.id" >
            {{ue.name }}
            </router-link>
            </p>
                  </td>
                  <td>

                    <span v-if="ue.mob && ue.country_code!='+91'">
                      {{  ue.mob.substring(0, 4) }} {{  ue.mob.substring(4, 11) }} 
                    </span>
                    <span v-else>
                      {{ ue.country_code }} {{  ue.mob }} 
                    </span>
                  </td>
									<td>
										<div class="d-flex justify-content-start align-items-center">
											<div class="me-3">
												
											</div>
											<div class="d-flex flex-column">
												<p class="mb-0 fw-medium">{{ date_format(ue.appoinment_date) }}</p>
											</div>
										</div>
									</td>
									
									
									<td>{{ ue.appoinment }}</td>
									
								</tr>
								
							</tbody>
						</table>
					</div>
				</div>
			</div>

      <div class="col-lg-12 mb-4 mt-3 mb-lg-0">
      <div class="card h-100">
        <div class="card-header d-flex justify-content-between">
          <h5 class="card-title m-0 me-2">Contacted Vendors</h5>
          
        </div>
        <div class="table-responsive">
          <table class="table table-bordered border-top">
            <thead class="border-bottom venue-color" >
              <tr>
                <th>Vendor Name</th>
                <th>Mobile number</th>
                <th>Updated Date</th>
                <th>Updates</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(vend_s,b) in booking_count.contacted_vendor" :key="b">
                <td>
                  <div class="d-flex justify-content-start align-items-center">
                    <div class="me-3">
                      
                    </div>
                    <div class="d-flex flex-column">
                      <p class="mb-0 fw-medium">
                        <router-link :to="'/Sales/view_sales_details/' + vend_s.id" >
          {{vend_s.name }}
          </router-link> </p>
                    </div>
                  </div>
                </td>
                
                <td>
                  <span v-if="vend_s.mob && vend_s.country_code!='+91'">
                    {{  vend_s.mob.substring(0, 4) }} {{  vend_s.mob.substring(4, 11) }} 
                  </span>
                  <span v-else>
                    {{ vend_s.country_code }} {{  vend_s.mob }} 
                  </span>
                 
                  </td>
                <td><p class="mb-0 fw-medium">{{ date_format(vend_s.log_date) }}</p></td>
                <td>{{ vend_s.lead_created }}</td>
              
              </tr>
              
            </tbody>
          </table>
        </div>
      </div>
    </div>
        </b-row>
     
   
 
    
  </div>
</template>
<script>
  import global from '../../mixins/global'; 
//import VueApexCharts from "vue-apexcharts";
 const themeColors = ["#2962ff", "#35b1e8", "#fbcd00", "#f64e60", "#0bb7af"];
import axios from "axios";
import Datepicker from "vuejs-datepicker";
export default {
  name: "Dashboard",
  mixins:[global],
  components: {
  Datepicker
},
  data: () => ({
    title: "Dashboard",
    parent_selected:'',
            child_selected:'',
    booking_count:'',
    allcounts:0,
    curncy:'',
    fires:0,
    dizers:0,
    water:0,
    exlamtry:0,
    types:'Nxt',
    calender:[],
    days:[],
    expected_date:[],
    expected_dates:[],
    exclametry:[],
    water_list:[],
    dizzers_list:[],
    fire_list:[],
    all_list:[],
    groupof_date:[],
    dublicated_check:[],
    month_year:new Date(),
            user_name:'',
            select_mode:'',
            timerCount: 0,
            page: 1,
            show: false,
            countDownToTime : new Date("Sep 26, 2029 00:00:00").getTime(),
            count: 0,
            totalData: "",
            totalRows: "",
            loadImage:localStorage.getItem('loadImage')?localStorage.getItem('loadImage') :'' ,
            logo:"require('@/assets/images/venue_booking/logo.svg')",
    total_dashboard_counts: [],
    all_source: [],
    bar_chart: {
      series: [
        {
          data: [44, 55, 41, 64, 22, 43, 21, 50, 67, 89, 12, 34],
        },
        {
          data: [53, 32, 33, 52, 13, 44, 32, 67, 78, 78, 89, 90],
        },
      ],
      chartOptions: {
        chart: {
          type: "bar",
          height: 300,
        },
        plotOptions: {
          bar: {
            vertical: true,
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: false,
          offsetX: -6,
          style: {
            fontSize: "12px",
            colors: ["#fff"],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "June",
            "July",
            "August",
            "Sepember",
            "October",
            "November",
            "December",
          ],
        },
      },
    },
    lineChartSimple: {
      series: [
        {
          name: "Desktops",
          data: [10, 41, 35, 51, 49, 62, 69, 91, 148, 59, 78, 90],
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          fontFamily: '"Nunito Sans",sans-serif',
          foreColor: "#a1aab2",
          zoom: {
            enabled: false,
          },
        },
        colors: themeColors,
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        title: {
          text: "",
          align: "left",
        },
        grid: {
          row: {
            opacity: 0.5,
          },
          borderColor: "rgba(0,0,0,0.1)",
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "June",
            "July",
            "August",
            "Sepember",
            "October",
            "November",
            "December",
          ],
          labels: {
            style: {
              cssClass: "grey--text lighten-2--text fill-color",
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              cssClass: "grey--text lighten-2--text fill-color",
            },
          },
        },
        tooltip: {
          theme: "dark",
        },
      },
    },
    searchChart: {
      series: [14, 15],
      chartOptions: {
        labels: ["Search Traffic", "Free"],
        dataLabels: {
          enabled: false,
        },
        grid: {
          padding: {
            left: 0,
            right: 0,
          },
        },
        chart: {},
        plotOptions: {
          pie: {
            donut: {
              size: "85px",
            },
          },
        },
        stroke: {
          width: 0,
        },
        legend: {
          show: false,
        },
        colors: ["#0dcaf0", "#0d6efd"],
        tooltip: {
          enabled: false,
          theme: "dark",
        },
      },
    },
    fields: [
      {
        key: "refno",
        label: "Booking Ref. No.",
        sortable: true,
      },
      {
        key: "customer_name",
        label: "Customer Name",
        sortable: true,
      },
      {
        key: "venue_name",
        label: "Venue Name",
        sortable: true,
      },
      {
        key: "date",
        label: "Booking Date",
        sortable: true,
      },
      {
        key: "price",
        label: "Price",
        sortable: true,
      },
    ],
    series1: [],

            chartOptions1: {
            chart: {
              width: '100%',
              type: 'pie',
            },
            labels: [],
            colors:['#FFC300', '#FF0000', '#B2FF00', '#088938', '#0AF2F6', '#0A57F6', '#0716A7', '#F10606', '#F10667', '#F106A4', '#E706F1', '#BC06F1', '#9F06F1','#412FE3', '#5006F1'],
            plotOptions: {
              pie: {
                dataLabels: {
                  offset: -5,
                
                }
              }
            },
            title: {
             // text: "Monochrome Pie"
            },
            dataLabels: {
                
              /* formatter(val, opts) {
                const name = opts.w.globals.labels[opts.seriesIndex]
                return [name, val.toFixed(1) + '%']
              } */
            },
            legend: {
              show: true,
            position: "bottom",
            horizontalAlign: "left",
        offsetX: -5,
        offsetY: 5,
        fontWeight: "bold",
            }
            },
     walletChart: {
      series: [
        {
          name: "Balance",
          data: [5,10,15,20,30,45],
        },
      ],
      chartOptions: {
        grid: {
          show: false,
          padding: {
            left: 0,
            right: 0,
          },
        },
        colors: ["#4fc3f7"],
        chart: {
          toolbar: {
            show: false,
          },
          sparkline: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 2,
        },
        markers: {
          size: 3,
          strokeColor: "transparent",
        },
        fill: {
          type: "solid",
          opacity: 0,
        },
        xaxis: {
          type: "numeric",
          lines: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          labels: { show: false },
        },
        yaxis: [
          {
            y: 0,
            offsetX: 0,
            offsetY: 0,
            padding: { left: 0, right: 0 },
          },
        ],
        tooltip: {
          x: { show: false },
           enabled: false,
          theme: "dark",
        },
      },
    },
      referralChart: {
      series: [
        {
          name: "Net Profit",
          data: [6, 10, 9, 11, 9, 10, 11],
        },
      ],

      chartOptions: {
        colors: ["#2962FF"],
        plotOptions: {
          bar: {
            horizontal: false,
            endingShape: "flat",
            columnWidth: "30%",
          },
        },
        chart: {
          fontFamily: '"Nunito Sans",sans-serif',
          foreColor: "#a1aab2",
          toolbar: {
            show: false,
          },
          sparkline: { enabled: true },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          show: false,
          labels: {
            show: false,
          },
        },
        yaxis: {
          show: false,
        },
        grid: {
          show: false,
          stroke: {
            show: false,
          },
          padding: {
            top: 0,
            bottom: 0,
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          enabled: false,
          theme: "dark",
        },
      },
    },
    items: [],
    get_upcoming_booking: [],
  }),
  methods: {
    getBookingCount() {
      
      axios.post("/admin/allSalesDashboard",{
                    parent_venue_id:this.parent_selected.value,
                    child_venue_id:this.child_selected.value,
                    mode:this.select_mode,
                    }).then((resp) => {
                    if (resp.data.status_code == 200) {
                        if (resp.data.status) {
                            this.booking_count = resp.data.total_counts;

                            this.dublicated_check=[];
                           
                            for (var a = 0;a < this.booking_count.upcoming_meeting_sales.length;a++) 
                            {
                              
                              if(this.booking_count.upcoming_meeting_sales.indexOf(this.booking_count.upcoming_meeting_sales[a].name) == -1)
                              {
                                this.dublicated_check.push({
                                "appoinment_date":this.booking_count.upcoming_meeting_sales[a].appoinment_date,
                                "name":this.booking_count.upcoming_meeting_sales[a].name,
                                "appoinment":this.booking_count.upcoming_meeting_sales[a].appoinment,
                                "country_code":this.booking_count.upcoming_meeting_sales[a].country_code,
                                "mob":this.booking_count.upcoming_meeting_sales[a].mob,
                                "id":this.booking_count.upcoming_meeting_sales[a].id,
                              })
                              }
                              
                            }
                           


                           // this.allcounts = parseInt(this.booking_count.total_confimed_booking)+ parseInt(this.booking_count.total_blocked_booking)+ parseInt(this.booking_count.total_draft_booking) + parseInt(this.booking_count.total_quot_booking)
                            
                        }
                    }
                });
    },
    getBookingCounts() {
                axios.post("/admin/get_vendor_dashboard_count",{
                    parent_venue_id:this.parent_selected.value,
                    child_venue_id:this.child_selected.value,
                    mode:this.select_mode,
                    }).then((resp) => {
                    if (resp.data.status_code == 200) {
                        if (resp.data.status) {
                            this.booking_count = resp.data.total_counts;
                            this.allcounts = parseInt(this.booking_count.total_confimed_booking)+ parseInt(this.booking_count.total_blocked_booking)+ parseInt(this.booking_count.total_draft_booking) + parseInt(this.booking_count.total_quot_booking)
                            
                        }
                    }
                });
            },
            getUserDetails() {
                axios.get("/admin/get_profile").then((resp) => {
                    if (resp.data.status_code == 200 && resp.data.status) {
                        this.$store.commit("SET_KYC_NOTIFICATION", resp.data.user_details);
            this.curncy= resp.data.user_details.currencys;
                        this.user_name= resp.data.user_details.name;
                        this.role_id= resp.data.user_details.role_id;
            this.role_id = resp.data.users.role_id;
                    
                        this.logo= process.env.VUE_APP_APIURL+''+resp.data.user_details.logo;
                        this.loadImage= process.env.VUE_APP_APIURL+''+resp.data.user_details.logo;
                        localStorage.setItem('loadImage',this.logo);
                        this.show= false;
                        
                        
                    }
                });

            },
            getBookingList() {
                axios.post("/admin/get_vendor_bookings").then((resp) => {
                    if (resp.data.status_code == 200) {
                        this.get_recent_booking = [];
                        this.get_upcoming_booking = [];
                        this.pie_series = [];
                        if (resp.data.status) {

              // for upcoming
                            
                            for (
                            var a = 0;
                            a < resp.data.bookings.get_upcoming_booking.bookings.length;
                            a++
                            ) {
                                
                                this.get_upcoming_booking.push({
                                    addon:
                                    resp.data.bookings.get_upcoming_booking.bookings[a].addOns? resp.data.bookings.get_upcoming_booking.bookings[a].addOns : '', 
                                    date:
                                    resp.data.bookings.get_upcoming_booking.bookings[a].from_date ,
                                    refno: resp.data.bookings.get_upcoming_booking.bookings[a].booking_auto_id,
                                    customer_name: resp.data.bookings.get_upcoming_booking.bookings[a].billing_first_name,
                                venue_name:resp.data.bookings.get_upcoming_booking.bookings[a].child_venue.child_venue_name.substring(0,25),
                                    price:resp.data.bookings.get_upcoming_booking.bookings[a].total_booking_price,  
                                    ament:resp.data.bookings.get_upcoming_booking.bookings[a].amenties,
                                });
                            }
                            // for incoming
                            
                            this.Activity_logs=resp.data.bookings.Activity_logs;
                            this.last_transaction=resp.data.bookings.last_transaction;
                            for (let k = 0; k < resp.data.bookings.pie.pie_array.length; k++) 
                            {
                                //this.series_[0].data.push(resp.data.bookings.pie.pie_array[k]);
                                this.series1.push(resp.data.bookings.pie.pie_array[k]);
                                this.chartOptions1.labels.push(resp.data.bookings.pie.name_arry[k]);
                            }
                            
                            
                            for (let l = 0; l < resp.data.bookings.getMonthlyData.month.length; l++) 
                            {
                                this.$refs.booking_charts.refresh(
                                ( this.chartOptions_.xaxis.categories.push(resp.data.bookings.getMonthlyData.month[l])),
                                ( this.series_[0].data.push(resp.data.bookings.getMonthlyData.amount[l]))
                                );
                                
                            }
                            
                            
                            this.Block_details=resp.data.bookings.Block_details;
                            
                            
                            
                        
                        }
                    }
                });
            },
            front_calendar(type)
            {
              axios.post("/admin/front_calendar",{month_year:this.month_year,type:type}).then((resp) => {
               
                   /*  if (resp.data.status_code == 200 && resp.data.status) { */
this.fires = resp.data.data.fires;
this.dizers = resp.data.data.dizers;
this.water = resp.data.data.water;
this.exlamtry = resp.data.data.exlamtry;
this.calender = resp.data.data.calender;
this.days = resp.data.data.days;
this.month_year = resp.data.data.Selected_date;
this.expected_date = resp.data.data.expected_date;

this.expected_dates = resp.data.data.expected_dates;

this.fire_list = resp.data.data.fire_list;
this.exclametry = resp.data.data.exclametry; //exclametry water_list dizzers_list
this.water_list = resp.data.data.water_list;
this.dizzers_list = resp.data.data.dizzers_list;

this.all_list = resp.data.data.all_list;
this.groupof_date = resp.data.data;

                 //   }
                  });
            },
      numberWithCommas(x) {
           /*  if(x)
            {
                return x.toString().split('.')[0].length > 3 ? x.toString().substring(0,x.toString().split('.')[0].length-3).replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + x.toString().substring(x.toString().split('.')[0].length-3): x.toString();
            }
            else
            {
                return 0;
            } */
            var num = x ? x : 0;
                return num.toLocaleString('en-IN')
        },
     
   currency_check(amt) {
                return '<i class="mdi '+this.curncy+'"></i>'+amt;
            },
            check_calendar_expected_date(day,key)
            {
              
              return this.expected_date[day] && this.expected_date[day][key]  ? this.expected_date[day][key] :'';
            }
  },
  mounted() {
    this.getUserDetails();
    this.getBookingCount();
    this.getBookingList();
    this.front_calendar('Nrl');
  },
 // components: { VueApexCharts },
};
</script>
<style >
#icon_first {
  font-size: 35px;
}
.card-header {
  font-size: 20px;
}


.extraline {
border: none !important;
text-align: center;
}
.extraline div #datePickerIds
{
text-align: center !important;
background: unset;
}
.vdp-datepicker * {
  border: none;
  text-align: center;
}
ul.list_view {
list-style-type: none;
margin: 0;
padding: 0;
  font-family: sans-serif;
}
.tr_colors
{
background:mintcream !important;
}
.underline_remove
{
padding: 10px;
  list-style-type: none;
  border-bottom: 1px solid #e3e0e0;
}
</style>